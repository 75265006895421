import { render, staticRenderFns } from "./addarticle.vue?vue&type=template&id=289ab906&scoped=true&"
import script from "./addarticle.vue?vue&type=script&lang=js&"
export * from "./addarticle.vue?vue&type=script&lang=js&"
import style0 from "./addarticle.vue?vue&type=style&index=0&id=289ab906&prod&scoped=true&lang=css&"
import style1 from "./addarticle.vue?vue&type=style&index=1&id=289ab906&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "289ab906",
  null
  
)

export default component.exports