<template>
  <div>
    <v-dialog v-model="dialog" width="1229" scrollable persistent>
      <v-card>
        <v-card-title
          style="font-size: 20px; color: #363636; border: 1px solid #c0c0c0"
          class="pb-4"
        >
          <div>
            <v-icon class="mr-1" style="padding-bottom: 3px"
              >mdi-square-edit-outline</v-icon
            >
            {{ $t("editarticle") }}
          </div>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              $emit('close'),
                (departmentSelected = []),
                (userSelected = []),
                (isUser = true),
                $refs.form.reset(),
                DeletePDFfile()
            "
            color="#424242"
            style="border-radius: 8px !important"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="height: 600px">
          <v-form ref="form" lazy-validation>
            <v-row class="mt-5">
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-row>
                  <v-col cols="12">
                    <p style="color: #424242; font-size: 16px">
                      {{ $t("createdateM") }}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {{ editArticleData.createDate1 }} {{ $t("By") }}
                      {{ editArticleData.createBy }}
                    </p>
                    <p
                      v-if="editArticleData.updateBy != null"
                      style="color: #424242; font-size: 16px"
                    >
                      {{ $t("Last Update") }}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {{ editArticleData.updateDate1 }} {{ $t("By") }}
                      {{ editArticleData.updateBy }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <label for="title" class="font-style">
                      {{ $t("title") }}
                      <span style="color: red">*</span>
                    </label>
                    <v-text-field
                      :rules="titleRules"
                      outlined
                      dense
                      hide-details="auto"
                      id="title"
                      v-model="editArticleData.contentName"
                      class="rounded-lg"
                    ></v-text-field>
                  </v-col>
                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                    class="loading-circle"
                    color="green"
                  ></v-progress-circular>
                  <v-col cols="12">
                    <label for="description" class="font-style">
                      {{ $t("description") }}
                    </label>
                    <v-text-field
                      outlined
                      dense
                      hide-details="auto"
                      id="description"
                      v-model="editArticleData.wordDescription"
                      class="rounded-lg"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <label class="font-style" for="startTime">{{
                      $t("startdate")
                    }}</label>
                    <v-menu
                      v-model="startDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="rounded-lg"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          id="startDate"
                          append-icon="mdi-calendar"
                          v-model="editStartDate"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="secondary"
                        v-model="editStartDate"
                        @input="editstartDateDis"
                        :allowed-dates="startallowedDates"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <label class="font-style" for="endDate">{{
                      $t("enddate")
                    }}</label>
                    <v-menu
                      v-model="endDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="rounded-lg"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          id="endDate"
                          append-icon="mdi-calendar"
                          v-model="editEndDate"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="secondary"
                        v-model="editEndDate"
                        @input="endDateMenu = false"
                        :allowed-dates="endallowedDates"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-row style="margin-top: 5rem">
                  <v-col cols="12" xs="12" sm="12" md="6" lg="7">
                    <p
                      class="text-left font-style mb-1"
                      :style="fileError ? { color: 'red' } : {}"
                    >
                      {{ $t("Image Cover") }}
                    </p>
                    <div>
                      <v-card
                        width="290"
                        height="215"
                        style="
                          border: 4px dashed #c0c0c0;
                          box-shadow: unset;
                          background: #f8f8f8 0% 0% no-repeat padding-box;
                        "
                        @click="$refs.file.click()"
                        @dragover.prevent
                        @dragenter.prevent
                        @drop.prevent="dropImg($event)"
                      >
                        <v-img
                          :lazy-src="editArticleData.picturebackground"
                          :src="editArticleData.picturebackground"
                          @click="$refs.file.click()"
                          @mouseover="
                            editArticleData.picturebackground
                              ? (showEditImgTool = true)
                              : (showEditImgTool = false)
                          "
                          @mouseleave="
                            editArticleData.picturebackground
                              ? (showEditImgTool = false)
                              : (showEditImgTool = false)
                          "
                          :style="
                            fileError ? { border: '1px solid #FA5252' } : {}
                          "
                          width="240"
                          height="167"
                          style="border-radius: 8px !important"
                          class="mx-auto center mt-4"
                          v-if="editArticleData.picturebackground != null"
                        >
                          <v-card-title
                            v-if="showEditImgTool"
                            style="left: 15%; top: 15%; position: absolute"
                          >
                            <v-list
                              class="d-flex flex-column align-start"
                              style="padding: 0; border-radius: 8px"
                            >
                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="#424242"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="null, (previewimg = true)"
                                >
                                  <v-icon left>mdi-eye-outline</v-icon>
                                  {{ $t("preview") }}
                                </v-btn>
                              </v-list-item>

                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="error"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="
                                    editArticleData.picturebackground = null
                                  "
                                >
                                  <v-icon left>mdi-delete-outline</v-icon>
                                  {{ $t("delete") }}
                                </v-btn>
                              </v-list-item>
                            </v-list>
                          </v-card-title>
                        </v-img>

                        <v-img
                          v-if="editArticleData.picturebackground == null"
                          src="@/assets/image_loader.png"
                          width="64"
                          height="52"
                          class="mx-auto center"
                          style="margin-top: 2.5rem"
                          @click="$refs.file.click()"
                        ></v-img>
                        <input
                          type="file"
                          class="d-none"
                          ref="file"
                          accept="image/*"
                          @change="uploadImg($event, 1)"
                        />
                        <p
                          v-show="!editArticleData.picturebackground"
                          class="text-center mt-3"
                          style="
                            color: #424242;
                            font-size: 14px;
                            opacity: 0.6;
                            margin-bottom: 0;
                          "
                        >
                          {{ $t("DragDropfile") }}
                        </p>
                        <p
                          v-show="!editArticleData.picturebackground"
                          class="text-center"
                          style="color: #424242; font-size: 14px; opacity: 0.6"
                        >
                          {{ $t("OrBrowseFile") }} JPG, PNG"
                        </p>
                      </v-card>
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="5">
                    <v-row>
                      <v-btn
                        color="#A6CC39"
                        style="
                          font-size: 16px;
                          margin-top: 3rem;
                          float: left;
                          border-radius: 8px;
                          height: 40px;
                          width: 165px;
                        "
                        class="text-capitalize white--text btn_hover_effect"
                        @click="$refs.file.click()"
                      >
                        <v-icon class="mr-1">mdi-plus-box-outline</v-icon>
                        {{ $t("Add Cover") }}
                      </v-btn>
                      <p class="mt-3" style="color: #777777; font-size: 12px">
                        {{ $t("RecommendFormatPNG") }}
                      </p>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <label for="wordContent" class="font-style">
                  {{ $t("wordcontent") }}
                  <span style="color: red">*</span>
                </label>
                <v-textarea
                  :rules="wordContentRules"
                  outlined
                  no-resize
                  id="wordContent"
                  v-model="editArticleData.wordContentText"
                  class="rounded-lg"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" style="padding-top: 0">
                <label for="link" class="font-style">
                  {{ $t("link") }}
                </label>
                <v-text-field
                  outlined
                  dense
                  hide-details="auto"
                  id="link"
                  @input="checkLinkType"
                  v-model="editArticleData.link"
                  class="rounded-lg"
                ></v-text-field>
                <span v-if="notLink" style="color: red; font-size: 13px">{{
                  $t("invalidUrlLink")
                }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="3" lg="2" class="mt-1">
                <v-row class="pl-4">
                  <input
                    type="file"
                    accept="application/pdf"
                    class="d-none"
                    ref="pdffile"
                    @change="getpdfFile($event)"
                  />
                  <v-btn
                    :disabled="article.PDFButtonDisable"
                    color="#A6CC39"
                    @click="($refs.pdffile.value = null), $refs.pdffile.click()"
                    style="
                      font-size: 16px;
                      float: left;
                      border-radius: 8px;
                      height: 40px;
                    "
                    class="text-capitalize white--text btn_hover_effect"
                  >
                    <v-icon class="mr-1">mdi-folder-search-outline</v-icon>
                    {{ $t("Browse File") }}
                  </v-btn>
                  <span class="mt-1" style="color: #777777; font-size: 12px">{{
                    $t("RecommendFormatPDF")
                  }}</span>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="4" class="mt-1 pl-0">
                <label
                  for="browsefiles"
                  v-if="
                    editArticleData.pdFfilename != '' ||
                    editArticleData.pdFfilename == null
                  "
                  class="font-style"
                  :change="checkdisabledata(editArticleData.pdFfilename)"
                  >{{ editArticleData.pdFfilename }}</label
                >
                <v-icon
                  color="#FF6060"
                  @click="DeletePDFfile"
                  v-if="article.PDFButtonDisable"
                  style="
                    cursor: pointer;
                    border: 1px;
                    margin-left: 5px;
                    margin-bottom: 3px;
                  "
                  small
                  >mdi-delete</v-icon
                >
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="3" class="pt-0">
                <v-checkbox
                  color="#A6CC39"
                  v-model="editArticleData.allView"
                  :label="$t('allusercanview')"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="6" md="3" class="pt-0">
                <v-btn
                  color="#A6CC39"
                  style="
                    font-size: 16px;
                    margin-top: 13px;
                    float: right;
                    border-radius: 8px;
                  "
                  class="text-capitalize white--text btn_hover_effect"
                  @click="openList()"
                  >{{ $t("requesteduser") }}</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                  :items="selectedList"
                  loading-text="Loading... Please wait"
                  :headers="selectedListUserHeader"
                  hide-default-footer
                  @page-count="pageCount = $event"
                  :page.sync="page"
                  :no-data-text="$t('nodata')"
                >
                  <template v-slot:item.request="{ item }">
                    <v-row class="justify-center">
                      <v-checkbox
                        color="#A6CC39"
                        v-model="item.request"
                      ></v-checkbox>
                    </v-row>
                  </template>
                  <template v-slot:item.perDay="{ item }">
                    <div v-if="item.request">
                      <v-menu
                        ref="menu"
                        v-model="item.menu"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-on="on"
                            v-bind="attrs"
                            v-model="item.dateStatus"
                            prepend-inner-icon="mdi-calendar-blank-outline"
                            :rules="[perDayRules.required]"
                            outlined
                            dense
                            readonly
                            hide-details="auto"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="item.perDay"
                          :allowed-dates="allowedPerday"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="#A6CC39"
                            @click="item.menu = false"
                            >{{ $t("cancel") }}</v-btn
                          >
                          <v-btn
                            text
                            color="#A6CC39"
                            @click="
                              (item.menu = false),
                                (item.dateStatus = checkStatus(item.perDay)),
                                $refs.menu.save(item.perDay)
                            "
                            >{{ $t("ok") }}</v-btn
                          >
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </template>
                  <template v-slot:item.index="{ item }">
                    <v-btn
                      color="error"
                      text
                      style="font-size: 16px; color: #fa5252 !important"
                      class="text-capitalize"
                      @click="DeletefromUser(item)"
                      >{{ $t("delete") }}</v-btn
                    >
                  </template>
                  <template v-slot:no-data>
                    <p style="font-size: 16px">{{ $t("nodata") }}</p>
                  </template>
                </v-data-table>
                <v-row justify="end">
                  <div class="ma-5 d-flex">
                    <v-pagination
                      circle
                      v-model="page"
                      class="pagination"
                      :length="pageCount"
                    ></v-pagination>
                  </div>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-card class="customcardnoti">
                <v-card-subtitle
                  style="color: #424242; font-size: 18px; font-weight: bold"
                >
                  {{ $t("sendnotifications") }}
                </v-card-subtitle>
              </v-card>
            </v-row>
            <v-row class="ml-6 mr-15 mt-7" rows="12" sm="12" md="12" lg="12">
              <div class="ml-5">
                <v-card width="205px"
                        height="35px"
                        style="
                          border: 1px dashed #c0c0c0;
                          box-shadow: unset;
                          border-radius: 4px;
                          display: flex;
                          align-items: center;
                          justify-content: left;
                        "
                        >
                <v-checkbox
                  class="pb-5 pl-3"
                  :label="$t('inbox')"
                  color="#a6cc39"
                  v-model="inbox_flag"
                  hide-details
                ></v-checkbox
              ></v-card>
              </div>
              <div class="ml-10"
                >
                <v-card width="205px"
                        height="35px"
                        style="
                          border: 1px dashed #c0c0c0;
                          box-shadow: unset;
                          border-radius: 4px;
                          display: flex;
                          align-items: center;
                          justify-content: left;
                        "
                        >
                <v-checkbox
                class="pb-5 pl-3"
                  color="#a6cc39"
                  :label="$t('pushnotification')"
                  v-model="pushNoti_flag"
                  hide-details
                ></v-checkbox
              ></v-card>
            </div>
              
              <div class="ml-10">
                <v-card width="205px"
                        height="35px"
                        style="
                          border: 1px dashed #c0c0c0;
                          box-shadow: unset;
                          border-radius: 4px;
                          display: flex;
                          align-items: center;
                          justify-content: left;
                        "
                        >
                <v-checkbox
                 class="pb-5 pl-3"
                  color="#a6cc39"
                  :label="$t('line')"
                 v-model="line_flag"
                  hide-details
                ></v-checkbox>
              </v-card>
            </div>
              <div
              class="d-flex justify-start pl-10"
              >
                <v-card
                  width="300px"
                  height="35px"
                  style="
                    border: 1.5px dashed #c0c0c0;
                    box-shadow: unset;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: start;
                  "
                >
                <v-row
                    class="d-flex justify-start align-center"
                    no-gutters
                  >
                    <v-col class="d-flex justify-start align-center" cols="5 pl-5">
                      <v-checkbox
                        class="pb-5"
                        color="#a6cc39"
                        :label="$t('email')"
                        v-model="email_flag"
                        @change="handleCheckboxChange"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <template>
                      <v-col class="d-flex justify-start align-center">
                        <v-row class="d-flex justify-start align-center ml-3">
                          <v-radio-group v-model="selectedRadio" class="radio-group" :disable="!email_flag">
                            <v-row rows="12" class="radiobutton">
                              <div class="radio-wrapper">
                             
                             <div v-if="!email_flag" class="radio-placeholder"></div>
                            
                             <v-radio
                               v-else
                               value="thai"
                               class="radio-button"
                             ></v-radio>
                           </div>
                           <v-img
                              src="@/assets/thailandflag.svg"
                              width="35"
                              height="22"
                              class="ml-2"
                            ></v-img>
                            <div class="radio-wrapper ml-2">
                             
                             <div v-if="!email_flag" class="radio-placeholder"> </div>
                            <v-radio
                            v-else
                            class=" radio-button"
                             value="eng"
                            
                              >
                            </v-radio>
                            </div>
                            <v-img
                              src="@/assets/ukflag.svg"
                              width="35"
                              height="22"
                              class="ml-2"
                            ></v-img>
                            </v-row>
                            
                          </v-radio-group>
                         
                        </v-row>
                      </v-col>
                    </template>
                  </v-row>
                </v-card>
              </div>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-3 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize btnfont-style"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            text
            @click="
              $emit('close'),
                (departmentSelected = []),
                (userSelected = []),
                (isUser = true),
                $refs.form.reset(),
                DeletePDFfile()
            "
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize btnfont-style btn_hover_effect"
            style="color: #ffff; border: 1px solid #a6cc39; border-radius: 8px"
            color="#A6CC39"
            width="100"
            @click="submit()"
            >{{ $t("update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- cropimage -->
    <v-dialog
      v-model="dialogCrop"
      width="1000px"
      height="800px"
      overflow="hidden"
      scrollable
    >
      <v-card class="rounded-lg" overflow="hidden">
        <v-card-title
          class="white--text pb-4"
          style="background-color: #a6cc39; font-size: 30px"
          >Crop Image</v-card-title
        >
        <v-card-text
          class="pt-12"
          style="height: 550px; padding-left: 4rem; padding-right: 4rem"
        >
          <vue-cropper
            ref="cropper"
            :img="option.img"
            :output-size="option.size"
            :output-type="option.outputType"
            :info="true"
            :full="option.full"
            :can-move="option.canMove"
            :can-move-box="option.canMoveBox"
            :fixed-box="option.fixedBox"
            :original="option.original"
            :auto-crop="option.autoCrop"
            :auto-crop-width="option.autoCropWidth"
            :auto-crop-height="option.autoCropHeight"
            :center-box="option.centerBox"
            :high="option.high"
          ></vue-cropper>
        </v-card-text>
        <v-card-actions class="mt-3 pb-4" style="margin-right: 50px">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize btnfont-style"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            text
            @click="CancelCrop()"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize font-style btn_hover_effect"
            style="color: #ffff; border: 1px solid #a6cc39; border-radius: 8px"
            color="#A6CC39"
            width="100"
            @click="CropImage()"
            >{{ $t("submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- preview image -->
    <v-dialog
      v-model="previewimg"
      persistent
      width="800px"
      height="800px"
      overflow="hidden"
      content-class="elevation-0 preview_class"
    >
      <v-card overflow="hidden" color="transparent">
        <v-card-title style="padding-right: 0 !important">
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="previewimg = false"
            color="#FFFFFF"
            style="border-radius: 8px !important; border: 1px solid #ffffff30"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-img
          width="800px"
          height="500px"
          style="border-radius: 8px; margin: auto"
          :src="editArticleData.picturebackground"
        ></v-img>
      </v-card>
    </v-dialog>

    <!-- Participant list -->
    <v-dialog width="500" v-model="listDialog" scrollable>
      <v-card>
        <v-card-title>
          <v-tabs hide-slider v-model="tabs" class="pt-3">
            <v-tab
              href="#tab-1"
              class="btnfont-style text-capitalize"
              style="
                width: 165px;
                border-radius: 10px 0px 0px 10px;
                border: 1px solid #a6cc39;
                color: #424242;
              "
              >{{ $t("user") }}</v-tab
            >
            <v-tab
              href="#tab-2"
              style="
                width: 165px;
                border-radius: 0px 10px 10px 0px;
                border: 1px solid #a6cc39;
                color: #424242;
              "
              class="btnfont-style text-capitalize"
              >{{ $t("group") }}</v-tab
            >
          </v-tabs>
        </v-card-title>
        <div class="mt-p pt-3 ml-3 mr-8">
              <v-text-field
                outlined
                dense
                hide-details
                clearable
                placeholder="search..."
                v-model="searchUser"
                class="custom-padding mb-4 ml-3 mr-3"
              >
              </v-text-field>
            </div>
        <v-card-text style="height: 500px">
          <v-tabs-items v-model="tabs" class="mt-5">
            <v-tab-item value="tab-1">
              <v-data-table
                :items="filteredUsers"
                @toggle-select-all="SelectAllUser"
                item-key="userID"
                :loading="userLoading"
                loading-text="Loading... Please wait"
                v-model="userSelected"
                show-select
                :headers="userListHeader"
                hide-default-footer
                @page-count="userpageCount = $event"
                :page.sync="userpage"
                :no-data-text="$t('nodata')"
              >
                <template v-slot:no-data>
                  <p style="font-size: 16px">{{ $t("nodata") }}</p>
                </template>
              </v-data-table>
              <v-row no-gutters class="mt-0">
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="10"
                  lg="10"
                  xl="10"
                  class="p-0"
                >
                  <div class="d-flex text-left">
                    <v-pagination
                      circle
                      v-model="userpage"
                      class="pagination"
                      :length="userpageCount"
                    ></v-pagination>
                  </div>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <v-data-table
                :items="filteredDepartments"
                @toggle-select-all="SelectAllDept"
                item-key="groupID"
                :loading="departmentLoading"
                loading-text="Loading... Please wait"
                v-model="departmentSelected"
                show-select
                :headers="departmentListHeader"
                hide-default-footer
                @page-count="deptpageCount = $event"
                :page.sync="deptpage"
                :no-data-text="$t('nodata')"
              >
                <template v-slot:no-data>
                  <p style="font-size: 16px">{{ $t("nodata") }}</p>
                </template>
              </v-data-table>
              <v-row no-gutters>
                <div class="mt-1 d-flex">
                  <v-pagination
                    circle
                    v-model="deptpage"
                    class="pagination"
                    :length="deptpageCount"
                  ></v-pagination>
                </div>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-3 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize btnfont-style"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            text
            @click="CancelParticipant"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize btnfont-style btn_hover_effect"
            style="color: #ffff; border: 1px solid #a6cc39; border-radius: 8px"
            color="#A6CC39"
            width="100"
            @click="savePermissionList()"
            >{{ $t("save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { VueCropper } from "vue-cropper";
export default {
  components: {
    VueCropper,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    checkSelected(val, oldVal) {
      if ((val, oldVal)) {
        this.getSelectedList();
      }
    },
    userSelected(val) {
      if (val.length != 0) {
        this.departmentSelected = [];
        this.$store.commit("removeFirstTimeEditDataForArticle");
      }
    },
    departmentSelected(val) {
      if (val.length != 0) {
        this.userSelected = [];
        this.$store.commit("removeFirstTimeEditDataForArticle");
      }
    },
  },
  computed: {
    filteredUsers() {
      if (this.tabs === 'tab-1' && this.searchUser) {
        return this.users.filter((user) =>
          user.employeeName.toLowerCase().includes(this.searchUser.toLowerCase())
        );
      } else {
        return this.users;
      }
    },
    filteredDepartments() {
      if (this.tabs === 'tab-2' && this.searchUser) {
        return this.departments.filter((department) =>
          department.groupName.toLowerCase().includes(this.searchUser.toLowerCase())
        );
      } else {
        return this.departments;
      }
    },
    ...mapGetters([
      "editLibraryData",
      "editArticleData",
      "showFirstTimeEditDataForArticle",
    ]),
    isLink() {
      const urlRegex = /^(https?:\/\/(www\.)|(www\.))|(ftp|http(s)?:\/\/).+$/;
      let result;
      if (this.editArticleData.link != null) {
        if (this.editArticleData.link.length != 0) {
          result = urlRegex.test(this.editArticleData.link);
        } else {
          result = true;
        }
      } else {
        result = true;
      }

      return result;
    },
    timesRules() {
      return [(v) => !!v || ""];
    },
    dialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
    userListHeader() {
      return [
        {
          text: this.$t("name"),
          align: "left",
          value: "fullName",
        },
      ];
    },
    departmentListHeader() {
      return [
        {
          text: this.$t("name"),
          align: "left",
          value: "groupName",
        },
      ];
    },
    selectedListUserHeader() {
      return [
        {
          text: "#",
          align: "left",
          value: "no",
          width: "50px",
          sortable: false,
        },
        {
          text: this.$t("name"),
          align: "left",
          value: "fullName",
        },
        // {
        //   text: this.$t("viewpermission"),
        //   align: "center",
        //   value: "permission"
        // },
        {
          text: this.$t("request"),
          align: "center",
          value: "request",
          width: "190px",
        },
        // {
        //   text: this.$t("repeat"),
        //   align: "center",
        //   value: "times",
        // },
        {
          text: this.$t("expiredate"),
          align: "center",
          value: "perDay",
          width: "250px",
        },
        {
          text: this.$t("action"),
          align: "center",
          value: "index",
          width: "190px",
        },
      ];
    },
    selectedListDepartmentHeader() {
      return [
        {
          text: this.$t("name"),
          align: "center",
          value: "groupName",
        },
        // {
        //   text: this.$t("viewpermission"),
        //   align: "center",
        //   value: "permission"
        // },
        {
          text: this.$t("assign"),
          align: "center",
          value: "request",
        },
        // {
        //   text: this.$t("repeat"),
        //   align: "center",
        //   value: "times",
        // },
        {
          text: this.$t("expiredate"),
          align: "center",
          value: "perDay",
        },
        {
          text: this.$t("action"),
          align: "center",
          value: "index",
        },
      ];
    },
    checkSelected() {
      return this.editArticleData;
    },
    editStartDate: {
      get() {
        if (this.editArticleData.startContentDate)
          return this.editArticleData.startContentDate.split("T")[0];
        else return false;
      },
      set(e) {
        this.editArticleData.startContentDate = e;
      },
    },
    editEndDate: {
      get() {
        if (this.editArticleData.startContentDate)
          return this.editArticleData.endContentDate.split("T")[0];
        else return false;
      },
      set(e) {
        this.editArticleData.endContentDate = e;
      },
    },
    // selectedList: {
    //   get() {
    //     return this.editArticleData.userSelected;
    //   },
    //   set(e) {
    //     this.editArticleData.userSelected = e;
    //   }
    // },
    // isUser: {
    //   get() {
    //     if (this.editArticleData.isUserGroup == 2) return true;
    //     else return false;
    //   },
    //   set(e) {
    //     this.editArticleData.isUserGroup = e;
    //   }
    // },
    titleRules() {
      return [(v) => !!v || "Title is required"];
    },
    wordContentRules() {
      return [(v) => !!v || "Word Content is required"];
    },
  },
  data: () => ({
    searchUser: "",
    inbox_flag: false,
    pushNoti_flag: false,
    email_flag: false,
    line_flag: false,
    selectedRadio: null,
    notLink: false,
    previewimg: false,
    showEditImgTool: false,
    fileError: false,
    perDayRules: {
      // required: v => v.length > 11 || '',
      required: (v) => v.split("-").length > 2 || "",
    },
    dialogCrop: false,
    allowedStartDates: (val) => val >= new Date().toISOString().substr(0, 10),

    option: {
      img: "",
      size: 1,
      full: false,
      outputType: "png",
      canMove: true,
      fixedBox: true,
      original: false,
      canMoveBox: false,
      autoCrop: true,
      autoCropWidth: 800,
      autoCropHeight: 450,
      centerBox: false,
      high: true,
    },
    article: {
      title: "",
      description: "",
      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      wordContent: "",
      file: null,
      fileName: "",
      fileUrl: "",
      link: "",
      PDFUrl: "",
      PDFButtonDisable: false,
    },
    // isUser: true,
    selectedList: [],
    startDateMenu: false,
    endDateMenu: false,
    listDialog: false,
    userSelected: [],
    departmentSelected: [],
    tabs: "tab-1",
    users: [],
    departments: [],
    userLoading: true,
    departmentLoading: true,
    loading: false,
    userdataList: [],
    departmentdataList: [],
    userLength: 0,
    page: 1,
    pageCount: 0,
    userpage: 1,
    userpageCount: 0,
    deptpage: 1,
    deptpageCount: 0,
  }),
  mounted() {
    this.getUserData();
    this.getDepartment();
  },
  methods: {
    handleCheckboxChange() {
      if (this.email_flag) {
        // When checkbox is checked, set the default radio button value
        this.selectedRadio = 'thai';
      } else {
        // When checkbox is unchecked, clear the selected radio button
        this.selectedRadio = null;
      }
    },
    checkLinkType() {
      if (this.isLink || this.editArticleData.link === "") {
        this.notLink = false;
      } else {
        this.notLink = true;
      }
    },
    checkdisabledata(filename) {
      if (filename != null && filename != "") {
        this.article.PDFButtonDisable = true;
      } else {
        this.article.PDFButtonDisable = false;
      }
    },
    getSelectedList() {
      if (this.editArticleData) {
        this.userLength = this.editArticleData.userSelected.length;
        this.selectedList = this.editArticleData.userSelected.map((v, i) => ({
          ...v,
          userPermissionID: v.employeeID,
          index: i,
          no: i + 1,
        }));
        return this.selectedList;
      }
      return [];
    },
    startallowedDates: (val) =>
      new Date(val).getTime() + 86400000 >= Date.now(),
    endallowedDates(val) {
      return val >= this.editStartDate;
    },
    allowedPerday(val) {
      return val <= this.editEndDate && val >= this.editStartDate;
    },
    editcalcRes(e) {
      this.editEndDate = e;
    },
    editstartDateDis(selected) {
      this.startDateMenu = false;
      this.endallowedDates.editStartDate = selected;
      if (this.editStartDate > this.editEndDate) {
        this.editEndDate = this.editStartDate;
      }
    },
    CancelParticipant() {
      let that = this;
      that.listDialog = false;
      that.userdataList = [];
      that.departmentdataList = [];
    },
    SelectAllUser() {
      if (this.userSelected == undefined) {
        this.userSelected = this.users;
      } else if (this.userSelected.length == 0) {
        this.userSelected = this.users;
      } else {
        this.userSelected = [];
      }
    },
    SelectAllDept() {
      if (this.departmentSelected == undefined) {
        this.departmentSelected = this.departments;
      } else if (this.departmentSelected.length == 0) {
        this.departmentSelected = this.departments;
      } else {
        this.departmentSelected = [];
      }
    },
    dropImg(e) {
      let file = e.dataTransfer.files[0];
      if (!/(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(file.name)) {
        alert("Please choose image!");
        return false;
      }
      this.article.fileName = file.name;
      let reader = new FileReader();
      reader.onload = (f) => {
        // f.target.result contains the base64 encoding of the image
        let src;
        if (typeof f.target.result === "object") {
          src = window.URL.createObjectURL(new Blob([f.target.result]));
        } else {
          src = f.target.result;
        }
        this.option.img = src;
        this.dialogCrop = true;
      };
      reader.readAsArrayBuffer(file);
    },
    uploadImg(e, num) {
      let self = this;
      let file = e.target.files[0];
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert("Please choose image!");
        return false;
      }
      this.article.fileName = file.name;
      let reader = new FileReader();
      reader.onload = (f) => {
        let data;
        if (typeof f.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([f.target.result]));
        } else {
          data = f.target.result;
        }
        if (num === 1) {
          self.option.img = data;
          self.dialogCrop = true;
        }
      };
      reader.readAsArrayBuffer(file);
    },
    CancelCrop() {
      this.dialogCrop = false;
    },
    CropImage() {
      this.$refs.cropper.getCropData((data) => {
        this.getFile(data);
        this.dialogCrop = false;
      });
    },
    openList() {
      let temp = [];
      this.tabs = this.editArticleData.isUserGroup == "2" ? "tab-1" : "tab-2";
      this.listDialog = true;
      if (this.tabs == "tab-1") {
        if (this.selectedList.length > 0) {
          for (let i of this.selectedList) {
            const found = this.users.find(
              (c) => c.userID == i.userPermissionID
            );
            if (found != undefined) {
              temp.push(found);
            }
          }
        }
        this.userSelected = temp;
      } else {
        if (this.selectedList.length > 0) {
          for (let i of this.selectedList) {
            const found = this.departments.find(
              (c) => c.groupID == i.userPermissionID
            );
            if (found != undefined) {
              temp.push(found);
            }
          }
        }
        this.departmentSelected = temp;
      }
    },
    DeletefromUser(i) {
      this.selectedList.splice(
        this.selectedList.findIndex((item) => item.index === i.index),
        1
      );
      this.selectedList = this.selectedList.map((v, j) => ({
        ...v,
        no: j + 1,
      }));
      if (this.perList.length != 0) {
        this.perList.splice(
          this.perList.findIndex((item) => item.index === i.index),
          1
        );
        this.perList = this.perList.map((v, k) => ({
          ...v,
          no: k + 1,
        }));
      }
    },
    savePermissionList() {
      this.listDialog = false;
      let user = [];
      let department = [];
      if (this.tabs == "tab-1") {
        for (let i = 0; i < this.userSelected.length; i++) {
          const found = this.selectedList.find(
            (c) => c.employeeID == this.userSelected[i].userID
          );
          if (found == undefined) {
            const filteruserdata = this.userdataList.find(
              (d) => d.employeeID == this.userSelected[i].userID
            );
            if (filteruserdata == undefined) {
              user.push({
                employeeID: this.userSelected[i].userID,
                fullName: this.userSelected[i].fullName,
                isCheck: true,
                isUser: true,
                perDay: this.userSelected[i].perDay,
                //  permission: false,
                request: false,
                dateStatus: "Please Select", //Date Picker
                times: 1,
                userPermissionID: this.userSelected[i].userID,
                articleID: 0,
                index: this.userLength + 1,
                no: i + 1,
              });
            } else {
              user.push({
                employeeID: filteruserdata.employeeID,
                fullName: filteruserdata.fullName,
                isCheck: filteruserdata.isCheck,
                isUser: filteruserdata.isUser,
                perDay: filteruserdata.perDay,
                // permission: filteruserdata.permission,
                request: filteruserdata.request,
                dateStatus: filteruserdata.dateStatus,
                times: filteruserdata.times,
                userPermissionID: filteruserdata.userPermissionID,
                articleID: filteruserdata.articleID,
                index: filteruserdata.index,
                no: i + 1,
              });
            }
          } else {
            user.push({
              employeeID: found.employeeID,
              fullName: found.fullName,
              isCheck: true,
              isUser: found.isUser,
              perDay: found.perDay,
              // permission: found.permission,
              request: found.request,
              dateStatus: found.dateStatus,
              times: found.times,
              userPermissionID: found.employeeID,
              articleID: found.articleID,
              index: found.index,
              no: i + 1,
            });
          }
        }
        this.selectedList = user;
        this.userdataList = user;
        this.$store.commit("changeArticleGroup", 2);
      } else {
        for (let i = 0; i < this.departmentSelected.length; i++) {
          const found1 = this.selectedList.find(
            (c) => c.employeeID == this.departmentSelected[i].groupID
          );
          if (found1 == undefined) {
            const filterdepartdata = this.departmentdataList.find(
              (c) => c.employeeID == this.departmentSelected[i].groupID
            );
            if (filterdepartdata == undefined) {
              this.userLength = this.userLength + 1;
              department.push({
                employeeID: this.departmentSelected[i].groupID,
                fullName: this.departmentSelected[i].groupName,
                isCheck: true,
                isUser: false,
                perDay: this.departmentSelected[i].perDay,
                //permission: false,
                request: false,
                dateStatus: "Please Select", //Date Picker
                times: 1,
                userPermissionID: this.departmentSelected[i].groupID,
                articleID: 0,
                index: this.userLength,
                no: i + 1,
              });
            } else {
              department.push({
                employeeID: filterdepartdata.employeeID,
                fullName: filterdepartdata.fullName,
                isCheck: filterdepartdata.isCheck,
                isUser: filterdepartdata.isUser,
                perDay: filterdepartdata.perDay,
                // permission: filterdepartdata.permission,
                request: filterdepartdata.request,
                dateStatus: filterdepartdata.dateStatus,
                times: filterdepartdata.times,
                userPermissionID: filterdepartdata.userPermissionID,
                articleID: filterdepartdata.articleID,
                index: filterdepartdata.index,
                no: i + 1,
              });
            }
          } else {
            department.push({
              employeeID: found1.employeeID,
              fullName: found1.fullName,
              isCheck: true,
              isUser: found1.isUser,
              perDay: found1.perDay,
              // permission: found1.permission,
              request: found1.request,
              dateStatus: found1.dateStatus,
              times: found1.times,
              userPermissionID: found1.employeeID,
              articleID: found1.articleID,
              index: found1.index,
              no: i + 1,
            });
          }
        }
        this.selectedList = [];
        this.selectedList = department;
        this.departmentdataList = department;
        this.$store.commit("changeArticleGroup", 1);
      }
      this.perList =
        this.editArticleData.isUserGroup == 2
          ? this.userSelected
          : this.departmentSelected;
    },

    checkStatus(item) {
      if (item) {
        return item;
      } else {
        return "Please Select"; //Date Picker
      }
    },

    async getUserData() {
      this.userLoading = true;
      const res = await this.$axios.get(
        `${this.web_urlV6}EmployeeEXT/GetEmployeeExtAllV4?id=` +
          localStorage.getItem("companyID")
      );
      this.users = res.data.data.map((v) => ({
        ...v,
        permission: false,
        request: false,
        repeat: 0,
        menu: false,
        date: [],
        dateStatus: "Please Select", //Date Picker
        // index:i
      }));
      this.userLoading = false;
    },

    async getDepartment() {
      let self = this;
      this.departmentLoading = true;
      await this.$axios
        .get(
          `${this.web_url}Group/GetGroupALL?id=` +
            localStorage.getItem("companyID")
        )
        .then(function (res) {
          self.departments = res.data.data.map((v) => ({
            ...v,
            permission: false,
            request: false,
            repeat: 0,
            menu: false,
            date: [],
            dateStatus: "Please Select", //Date Picker
            // index:i
          }));
        })
        .catch(function (err) {
          alert(err);
        });

      self.departmentLoading = false;
    },

    getFile(e) {
      this.article.fileUrl = e;
      this.article.file = e;
      this.editArticleData.picturebackground = e;
      this.showEditImgTool = false;
    },
    async getpdfFile(e) {
      let that = this;
      let file = e.target.files[0];
      if (!/\.pdf$/.test(e.target.value)) {
        alert("Choose PDF files only, please re-select!");
        return false;
      } else {
        that.editArticleData.pdFfilename = file.name;
        that.article.PDFUrl = e;
        that.article.PDFButtonDisable = true;
      }
    },
    DeletePDFfile() {
      let that = this;
      that.editArticleData.pdFfilename = "";
      that.article.PDFUrl = "";
      that.article.PDFButtonDisable = false;
    },
    imageTobase64(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.article.file = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },

    async submit() {
      if (this.$refs.form.validate() && this.notLink === false) {
        this.loading = true;
        let permissionList = [];
        for (const element of this.selectedList) {
          permissionList.push({
            articleID: element.articleID,
            userPermissionID:
              element.userPermissionID == undefined
                ? element.employeeID
                : element.userPermissionID,
            //  permission: this.selectedList[i].permission,
            request: element.request,
            isUser: element.isUser,
            isComplete: false,
            times: parseInt(element.times),
            perDay: element.perDay == "" ? undefined : element.perDay,
          });
        }
        if (this.article.PDFUrl != "") {
          let formData = new FormData();
          formData.append("file", this.article.PDFUrl.target.files[0]);
          const resp = await this.$axios.post(
            `${this.web_url}File/UploadPDFFile`,
            formData
          );
          this.article.PDFUrl = resp.data.data;
        }
        let base64Res = await this.$axios.post(
          `${this.web_url}File/UploadFileStringBase`,
          {
            base64: this.article.file,
            fileName: this.article.fileName,
          }
        );

        const userdata = JSON.parse(
          localStorage.getItem("vivek_authenticated_user_data")
        );
        const memberid = userdata.memberId;
        const data = {
          id: parseInt(this.editArticleData.id),
          articlePermissionData: permissionList,
          attachFileName: this.article.fileName,
          contentName: this.editArticleData.contentName,
          endContentDate: this.editArticleData.endContentDate,
          isUserGroup: this.editArticleData.isUserGroup,
          pdfFile: "",
          pictureBackground: base64Res.data,
          startContentDate: this.editArticleData.startContentDate,
          topicID: parseInt(this.editLibraryData.id),
          wordContentFile: "",
          wordContentText: this.editArticleData.wordContentText,
          wordDescription: this.editArticleData.wordDescription,
          bgBase64: this.article.file,
          allView:
            this.editArticleData.allView == null
              ? false
              : this.editArticleData.allView,
          link: this.editArticleData.link,
          updateBy: memberid,
          pdfUrl: this.article.PDFUrl,
          pdFfilename: this.editArticleData.pdFfilename,
          inboxFlag: this.inbox_flag,
          emailFlag: this.email_flag,
          emailLanguage: this.selectedRadio,
          pushNotiFlag: this.pushNoti_flag,
          lineFlag: this.line_flag,
        };
       
        const res = await this.$axios.post(
          `${this.web_url}Contents/UpdateWordContent`,
          data
        );
    
        if (res.data.status == 0) {
          this.perList = [];
          this.selectedList = [];
          this.departmentSelected = [];
          this.userSelected = [];
          this.userdataList = [];
          this.departmentdataList = [];
          this.article = {
            title: "",
            description: "",
            startDate: new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .substr(0, 10),
            endDate: new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .substr(0, 10),
            wordContent: "",
            file: null,
            fileName: "",
            fileUrl: "",
            PDFUrl: "",
            PDFButtonDisable: false,
          };
          this.inbox_flag = false;
          this.email_flag = false;
          this.pushNoti_flag = false;
          this.line_flag = false;
          this.$refs.file.value = null;
          this.$emit("confirm");
          this.DeletePDFfile();
        }
        this.loading = false;
      } else {
        alert("Incomplete information Please enter again!");
      }
    },
  },
};
</script>
<style scoped>
.radiobutton{
  margin-top: -10px;
}
.radio-group {
  display: flex;
  align-items: center;
}

.radio-wrapper {
  position: relative;
  width: 22px;
  height: 22px;
}

.radio-placeholder,
.radio-button {
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.radio-placeholder {
  background-color:#424242;
  opacity: 0.1;
  pointer-events: none; /* Prevent interaction */
}

.customcardnoti {
  width: 98%;
  margin-left: 10px;
  background-color: #e0e0e0;
}
.loading-circle {
  position: fixed;
  z-index: 5;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}
::v-deep .v-dialog {
  border-radius: 35px !important;
}
::v-deep .preview_class.v-dialog {
  border-radius: 8px !important;
}
::v-deep .v-text-field__slot {
  font-size: 16px !important;
}
.font-style {
  font-size: 16px;
  color: #4d4f5c;
}
.btnfont-style {
  font-size: 20px;
}
::v-deep .v-input__slot {
  border-radius: 10px !important;
}
::v-deep .article-table tr:nth-child(even) {
  background: unset;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  background-color: #e0e0e0 !important;
  opacity: 1;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
}
::v-deep .accent {
  background-color: #a6cc39 !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
}
::v-deep .primary {
  background-color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
::v-deep .accent--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
}
::v-deep .v-tab--active {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
}
/* >>> .v-simple-checkbox .v-icon {
  color: #a6cc39 !important;
} */
::v-deep .v-simple-checkbox .mdi-checkbox-blank-outline {
  color: #c0c0c0 !important;
}
::v-deep .v-simple-checkbox .mdi-checkbox-marked {
  color: #a6cc39 !important;
}
::v-deep .v-simple-checkbox .mdi-minus-box {
  color: #a6cc39 !important;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.btn_hover_effect,
.reset-btn {
  color: #fff;
  width: 80%;
  font-size: 18px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.list-item-padding.v-sheet.v-list {
  border-radius: 8px !important;
}
.list-item-padding.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  border-radius: 8px !important;
}
.list-item-padding.list-item-padding.theme--light {
  padding: 0 5px !important;
  min-height: 42px !important;
}
.btn_hover_effect:before,
.reset-btn:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.btn_hover_effect:hover:before,
.reset-btn:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.btn_hover_effect:hover,
.reset-btn:hover {
  transition: 0.25s;
}
.btn_hover_effect:after,
.reset-btn:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
::v-deep .v-data-table thead th:first-child {
  border-radius: 8px 0 0 8px;
} /**.child_table */
::v-deep .v-data-table thead th:last-child {
  border-radius: 0 8px 8px 0;
}
</style>
