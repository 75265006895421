<template>
  <div>
    <v-dialog v-model="dialog" persistent width="1220" scrollable>
      <v-card>
        <v-card-title
          style="font-size: 20px; color: #363636; border: 1px solid #c0c0c0"
          class="pb-4"
        >
          <div>
            <v-icon class="mr-1" style="padding-bottom: 3px"
              >mdi-square-edit-outline</v-icon
            >
            {{ $t("editvideo") }}
          </div>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="close()"
            color="#424242"
            style="border-radius: 8px !important"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="height: 600px">
          <v-form ref="form" lazy-validation>
            <v-row class="mt-5">
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-row>
                  <v-col cols="12">
                    <p style="color: #424242; font-size: 16px">
                      {{ $t("createdateM") }}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {{ editVideoData.createDate1 }} {{ $t("By") }}
                      {{ editVideoData.createBy }}
                    </p>
                    <p
                      v-if="editVideoData.updateBy != null"
                      style="color: #424242; font-size: 16px"
                    >
                      {{ $t("Last Update") }}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {{ editVideoData.updateDate1 }} {{ $t("By") }}
                      {{ editVideoData.updateBy }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <label for="title" class="font-style">
                      {{ $t("title") }}
                      <span style="color: red">*</span>
                    </label>
                    <v-text-field
                      :rules="titleRules"
                      outlined
                      dense
                      hide-details="auto"
                      id="title"
                      v-model="editVideoData.lessonName"
                      class="rounded-lg"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label for="description" class="font-style">{{
                      $t("description")
                    }}</label>
                    <v-text-field
                      outlined
                      dense
                      hide-details="auto"
                      id="description"
                      v-model="editVideoData.wordDescription"
                      class="rounded-lg"
                    ></v-text-field>
                  </v-col>
                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                    class="loading-circle"
                    color="green"
                  ></v-progress-circular>
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <label for="startTime" class="font-style">
                      {{ $t("startdate") }}
                    </label>
                    <v-menu
                      v-model="startDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="rounded-lg"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          id="startDate"
                          append-icon="mdi-calendar"
                          v-model="startContentDate"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :allowed-dates="allowedStartDates"
                        color="secondary"
                        v-model="startContentDate"
                        @input="startContentDateDis"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <label for="endDate" class="font-style">
                      {{ $t("enddate") }}
                    </label>
                    <v-menu
                      v-model="endDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="rounded-lg"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          id="endDate"
                          append-icon="mdi-calendar"
                          v-model="endContentDate"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :allowed-dates="allowedEndDates"
                        color="secondary"
                        v-model="endContentDate"
                        @input="endDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="7">
                    <p
                      class="text-left font-style"
                      :style="fileError ? { color: 'red' } : {}"
                    >
                      {{ $t("Image Cover") }}
                    </p>
                    <div>
                      <v-card
                        width="290"
                        height="215"
                        style="
                          border: 4px dashed #c0c0c0;
                          box-shadow: unset;
                          background: #f8f8f8 0% 0% no-repeat padding-box;
                        "
                        @click="$refs.file.click()"
                        @dragover.prevent
                        @dragenter.prevent
                        @drop.prevent="dropImg($event)"
                      >
                        <v-img
                          :lazy-src="editVideoData.picturebackground"
                          :src="editVideoData.picturebackground"
                          @click="$refs.file.click()"
                          @mouseover="
                            editVideoData.picturebackground
                              ? (showEditImgTool = true)
                              : (showEditImgTool = false)
                          "
                          @mouseleave="
                            editVideoData.picturebackground
                              ? (showEditImgTool = false)
                              : (showEditImgTool = false)
                          "
                          :style="
                            fileError ? { border: '1px solid #FA5252' } : {}
                          "
                          width="240"
                          height="167"
                          style="border-radius: 8px !important"
                          class="mx-auto center mt-4"
                          v-if="editVideoData.picturebackground != null"
                        >
                          <v-card-title
                            v-if="showEditImgTool"
                            style="left: 15%; top: 15%; position: absolute"
                          >
                            <v-list
                              class="d-flex flex-column align-start"
                              style="padding: 0; border-radius: 8px"
                            >
                              <v-list-item class="list-item-padding preview">
                                <v-btn
                                  color="#424242"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="previewimg = true"
                                >
                                  <v-icon left>mdi-eye-outline</v-icon>
                                  {{ $t("preview") }}
                                </v-btn>
                              </v-list-item>

                              <v-list-item class="list-item-padding preview">
                                <v-btn
                                  color="error"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="
                                    editVideoData.picturebackground = null
                                  "
                                >
                                  <v-icon left>mdi-delete-outline</v-icon>
                                  {{ $t("delete") }}
                                </v-btn>
                              </v-list-item>
                            </v-list>
                          </v-card-title>
                        </v-img>

                        <v-img
                          v-if="editVideoData.picturebackground == null"
                          src="@/assets/image_loader.png"
                          width="64"
                          height="52"
                          class="mx-auto center"
                          style="margin-top: 2.5rem"
                          @click="$refs.file.click()"
                        ></v-img>
                        <input
                          type="file"
                          class="d-none"
                          ref="file"
                          accept="image/*"
                          @change="uploadImg($event, 1)"
                        />
                        <p
                          v-show="!editVideoData.picturebackground"
                          class="text-center mt-3"
                          style="
                            color: #424242;
                            font-size: 14px;
                            opacity: 0.6;
                            margin-bottom: 0;
                          "
                        >
                          {{ $t("DragDropfile") }}
                        </p>
                        <p
                          v-show="!editVideoData.picturebackground"
                          class="text-center"
                          style="color: #424242; font-size: 14px; opacity: 0.6"
                        >
                          {{ $t("OrBrowseFile") }} JPG"
                        </p>
                      </v-card>
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="5">
                    <v-row>
                      <v-btn
                        color="#A6CC39"
                        style="
                          font-size: 16px;
                          margin-top: 3rem;
                          float: left;
                          border-radius: 8px;
                          height: 40px;
                          width: 165px;
                        "
                        class="text-capitalize white--text btn_hover_effect"
                        @click="$refs.file.click()"
                      >
                        <v-icon class="mr-1">mdi-plus-box-outline</v-icon>
                        {{ $t("Add Cover") }}
                      </v-btn>
                      <p class="mt-3" style="color: #777777; font-size: 12px">
                        {{ $t("RecommendFormatPNG") }}
                      </p>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <div class="editonoffswitch mt-4 ml-5">
                  <input
                    type="checkbox"
                    name="editonoffswitch"
                    class="editonoffswitch-checkbox"
                    id="editmyonoffswitch"
                    checked
                    v-model="editVideoData.viewerFlag"
                  />
                  <label class="editonoffswitch-label" for="editmyonoffswitch">
                    <div class="editonoffswitch-inner"></div>
                    <div class="editonoffswitch-switch"></div>
                  </label>
                </div>
                <div class="classdivider">
                  <v-divider
                    vertical
                    style="
                      border-color: #424242;
                      opacity: 0.2;
                      margin-left: 6.5rem;
                      margin-top: -6rem;
                      min-height: 4em;
                    "
                  ></v-divider>
                </div>

                <div class="labelswitch">
                  <label
                    for="viewersettings"
                    style="font-size: 16px; color: #000000; font-weight: bold"
                    >{{ $t("viewersettingsvideo") }}
                  </label>
                </div>
                <div class="labelswitchs">
                  <label for="allowviewersrewind" style="font-size: 14px">
                    {{ $t("allowviewersrewindvideo") }}
                  </label>
                </div>
              </v-col>

              <v-col cols="4">
                <div class="ml-13">
                  <v-row class="ml-10">
                    <v-col md="6" sm="6" lg="4" class="ml-10">
                      <input
                        type="file"
                        class="d-none"
                        ref="video"
                        accept="video/mp4"
                        @change="getVideo"
                      />
                      <v-btn
                        color="#A6CC39"
                        style="
                          font-size: 16px;
                          float: left;
                          border-radius: 8px;
                          height: 40px;
                        "
                        class="text-capitalize white--text btn_hover_effect"
                        width="180"
                        @click="$refs.video.click()"
                      >
                        <v-icon class="mr-1">mdi-folder-search-outline</v-icon>
                        {{ $t("browseFiles") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class="ml-10">
                    <v-col
                      class="pt-2 ml-10"
                      cols="12"
                      md="6"
                      sm="6"
                      lg="7"
                      style="padding-top: 0px"
                    >
                      <div
                        style="
                          font-size: 16px;
                          color: #777777;
                          word-break: break-word;
                          width: 270px;
                        "
                      >
                        <span> {{ $t("RecommendFormatMP4") }} <br /> </span>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="videoHeaders"
                  :loading="soundLoading"
                  :items="videoList"
                  loading-text="Loading... Please wait"
                  hide-default-footer
                  :expanded.sync="expanded"
                  :single-expand="true"
                  item-key="songName"
                  disable-pagination
                  :no-data-text="$t('nodata')"
                >
                  <template v-slot:item="{ item, expand, isExpanded }">
                    <tr>
                      <td>{{ item.part }}</td>
                      <td>
                        <v-hover v-slot="{ hover }">
                          <v-btn
                            class="text-capitalize"
                            text
                            style="
                              font-size: 16px;
                              border-radius: 8px;
                              background-color: #ffffff;
                            "
                            :style="{
                              border:
                                hover || isExpanded == true
                                  ? '1px solid #AECB53'
                                  : '1px solid #C0C0C0',
                              color:
                                hover || isExpanded == true
                                  ? '#AECB53'
                                  : '#424242',
                            }"
                            @click="checkPermission(item), expand(!isExpanded)"
                          >
                            <v-icon small class="mr-2"
                              >mdi-account-lock-outline</v-icon
                            >
                            {{ $t("permission") }}
                            <v-icon v-if="!isExpanded" class="ml-3"
                              >mdi-chevron-right</v-icon
                            >
                            <v-icon v-else class="ml-3"
                              >mdi-chevron-down</v-icon
                            >
                          </v-btn>
                        </v-hover>
                      </td>
                      <td>{{ item.songName }}</td>
                      <td>{{ item.duration }}</td>
                      <td>
                        <div class="d-flex">
                          <v-menu open-on-hover offset-y min-width="55px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                width="55"
                                height="30"
                                color="#47484b"
                                class="text-capitalize mt-1 sort_Btn"
                                outlined
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ item.sort }}
                                <v-icon
                                  style="
                                    margin-left: 0px;
                                    margin-right: -0.6rem;
                                  "
                                  >mdi-menu-down</v-icon
                                >
                              </v-btn>
                            </template>
                            <v-list
                              :style="
                                videoList.length == 1
                                  ? { height: '69px' }
                                  : videoList.length == 2
                                  ? { height: '103px' }
                                  : videoList.length == 3
                                  ? { height: '136px' }
                                  : videoList.length == 4
                                  ? { height: '170px' }
                                  : { height: '205px' }
                              "
                              class="overflow-y-auto"
                            >
                              <v-list-item
                                v-for="(temp, index) in sortNumberItems"
                                :key="index"
                                @click="item.sort = selectNull(temp.value)"
                              >
                                <v-list-item-title class="custom_list_item">{{
                                  temp.value
                                }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                      </td>
                      <td style="text-align: center">
                        <v-btn
                          color="#FF6060"
                          class="text-capitalize font-style"
                          text
                          @click="checkItemDelete(item)"
                        >
                          <v-icon class="mr-1">mdi-delete-outline</v-icon>
                          {{ $t("Remove") }}
                        </v-btn>
                      </td>
                    </tr>
                  </template>

                  <template v-slot:expanded-item="{ headers }">
                    <tr style="background-color: unset !important">
                      <td style="padding-bottom: 30px; padding-top: 7px">
                        <v-divider
                          vertical
                          style="
                            border-color: #424242;
                            opacity: 0.2;
                            margin-left: 1.5rem;
                          "
                        ></v-divider>
                      </td>
                      <td
                        class="py-0"
                        :colspan="headers.length - 1"
                        style="padding: 0 !important"
                      >
                        <v-data-table
                          :items="selectedList"
                          :headers="selectedHeader"
                          item-key="fullName"
                          elevation="0"
                          class="child_table mt-1"
                          hide-default-footer
                          @page-count="pageCount = $event"
                          :page.sync="page"
                          :no-data-text="$t('nodata')"
                        >
                          <template v-slot:item.request="{ item }">
                            <v-checkbox
                              style="
                                width: 50px;
                                float: right;
                                padding-bottom: 10px;
                                margin-right: 10px;
                              "
                              hide-details
                              color="secondary"
                              v-model="item.request"
                            ></v-checkbox>
                          </template>
                          <template v-slot:item.perDay="{ item }">
                            <div v-if="item.request">
                              <v-menu
                                ref="menu"
                                v-model="item.menu"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                                :close-on-content-click="false"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-on="on"
                                    v-bind="attrs"
                                    v-model="item.selectedDate"
                                    prepend-inner-icon="mdi-calendar-blank-outline"
                                    :rules="[perDayRules.required]"
                                    outlined
                                    dense
                                    readonly
                                    hide-details="auto"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  :allowed-dates="allowedPerday"
                                  v-model="item.perDay"
                                  no-title
                                  scrollable
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text
                                    color="#A6CC39"
                                    class="font-style"
                                    @click="item.menu = false"
                                    >{{ $t("cancel") }}</v-btn
                                  >
                                  <v-btn
                                    text
                                    class="font-style"
                                    color="#A6CC39"
                                    @click="
                                      (item.menu = false),
                                        (item.selectedDate = checkStatus(
                                          item.perDay
                                        )),
                                        $refs.menu.save(item.perDay)
                                    "
                                    >{{ $t("ok") }}</v-btn
                                  >
                                </v-date-picker>
                              </v-menu>
                            </div>
                          </template>
                          <template
                            v-slot:item.index="{ item }"
                            style="text-align: center"
                          >
                            <v-btn
                              color="#FF6060"
                              text
                              style="color: #fa5252 !important"
                              class="text-capitalize font-style pl-0"
                              @click="DeletefromUser(item)"
                            >
                              <v-icon class="mr-1">mdi-delete-outline</v-icon>
                              {{ $t("Remove") }}
                            </v-btn>
                          </template>
                          <template v-slot:no-data>
                            <p style="font-size: 16px">{{ $t("nodata") }}</p>
                          </template>
                        </v-data-table>
                        <v-row v-if="selectedList.length > 0" justify="end">
                          <div class="mt-3 mr-2 d-flex">
                            <v-pagination
                              circle
                              v-model="page"
                              class="pagination"
                              :length="pageCount"
                            ></v-pagination>
                          </div>
                        </v-row>
                        <v-row>
                          <v-col class="pt-0" cols="12" sm="6" md="3">
                            <v-checkbox
                              v-model="allView"
                              @click="checkAllView(allView)"
                              :label="$t('allusercanview')"
                            ></v-checkbox>
                          </v-col>
                          <v-spacer></v-spacer>
                          <v-col cols="12" sm="6" md="3" class="mr-1 pt-0">
                            <v-btn
                              color="#ffffff"
                              style="
                                font-size: 16px;
                                margin-top: 13px;
                                float: right;
                                color: #a6cc39;
                                border: 1px solid #aecb53;
                                border-radius: 8px;
                                box-shadow: unset;
                              "
                              class="text-capitalize"
                              @click="AddParticipant()"
                            >
                              <v-icon class="mr-1"
                                >mdi-account-multiple-plus-outline</v-icon
                              >
                              {{ $t("requesteduser") }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-card class="customcardnoti">
                <v-card-subtitle
                  style="color: #424242; font-size: 18px; font-weight: bold"
                >
                  {{ $t("sendnotifications") }}
                </v-card-subtitle>
              </v-card>
            </v-row>
            <v-row class="ml-6 mr-15 mt-7" rows="12" sm="12" md="12" lg="12">
              <div class="ml-3" >
                <v-card width="205px"
                        height="35px"
                        style="
                          border: 1px dashed #c0c0c0;
                          box-shadow: unset;
                          border-radius: 4px;
                          display: flex;
                          align-items: center;
                          justify-content: start;
                        "
                        >
                <v-checkbox
                  class="pb-5 pl-3"
                  :label="$t('inbox')"
                  color="#a6cc39"
                  v-model="inbox_flag"
                  hide-details
                ></v-checkbox
              ></v-card>
              </div>
              <div class="ml-10"
                >
                <v-card width="205px"
                        height="35px"
                        style="
                          border: 1px dashed #c0c0c0;
                          box-shadow: unset;
                          border-radius: 4px;
                          display: flex;
                          align-items: center;
                          justify-content: start;
                        "
                        >
                <v-checkbox
                class="pb-5 pl-3"
                  color="#a6cc39"
                  :label="$t('pushnotification')"
                  v-model="pushNoti_flag"
                  hide-details
                ></v-checkbox
              ></v-card>
            </div>
              
              <div class="ml-10" >
                <v-card width="205px"
                        height="35px"
                        style="
                          border: 1px dashed #c0c0c0;
                          box-shadow: unset;
                          border-radius: 4px;
                          display: flex;
                          align-items: center;
                          justify-content: start;
                        "
                        >
                <v-checkbox
                 class="pb-5 pl-3"
                  color="#a6cc39"
                  :label="$t('line')"
                 v-model="line_flag"
                  hide-details
                ></v-checkbox>
              </v-card>
            </div>
              <div
              class="d-flex justify-center ml-10"
              >
                <v-card
                  width="300px"
                  height="35px"
                  style="
                    border: 1.5px dashed #c0c0c0;
                    box-shadow: unset;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: start;
                  "
                >
                <v-row
                    class="d-flex justify-start align-center"
                    no-gutters
                  >
                    <v-col class="d-flex justify-start align-center" cols="5">
                      <v-checkbox
                        class="pb-5 pl-3"
                        color="#a6cc39"
                        :label="$t('email')"
                        v-model="email_flag"
                        @change="handleCheckboxChange"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <template>
                      <v-col class="d-flex justify-start align-center">
                        <v-row class="d-flex justify-start align-center pl-3">
                          <v-radio-group v-model="selectedRadio" class="radio-group" :disable="!email_flag">
                            <v-row rows="12" class="radiobutton">
                              <div class="radio-wrapper">
                             
                             <div v-if="!email_flag" class="radio-placeholder"></div>
                            
                             <v-radio
                               v-else
                               value="thai"
                               class="radio-button"
                             ></v-radio>
                           </div>
                           <v-img
                              src="@/assets/thailandflag.svg"
                              width="35"
                              height="22"
                              class="ml-2"
                            ></v-img>
                            <div class="radio-wrapper ml-2">
                             
                             <div v-if="!email_flag" class="radio-placeholder"> </div>
                            <v-radio
                            v-else
                            class=" radio-button"
                             value="eng"
                            
                              >
                            </v-radio>
                            </div>
                            <v-img
                              src="@/assets/ukflag.svg"
                              width="35"
                              height="22"
                              class="ml-2"
                            ></v-img>
                            </v-row>
                            
                          </v-radio-group>
                         
                        </v-row>
                      </v-col>
                    </template>
                  </v-row>
                </v-card>
              </div>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-3 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize btnfont-style"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            text
            @click="close()"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            class="text-capitalize btnfont-style btn_hover_effect"
            style="color: #ffff; border: 1px solid #a6cc39; border-radius: 8px"
            color="#A6CC39"
            width="100"
            @click="submit()"
            >{{ $t("submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- cropimage -->
    <v-dialog
      v-model="dialogCrop"
      width="1000px"
      height="800px"
      overflow="hidden"
    >
      <v-card class="rounded-lg" overflow="hidden">
        <v-card-title
          class="white--text pb-4"
          style="background-color: #a6cc39; font-size: 30px"
          >Crop Image</v-card-title
        >
        <v-card-text
          class="pt-12"
          style="height: 550px; padding-left: 4rem; padding-right: 4rem"
        >
          <vue-cropper
            ref="cropper"
            :img="option.img"
            :output-size="option.size"
            :output-type="option.outputType"
            :info="true"
            :full="option.full"
            :can-move="option.canMove"
            :can-move-box="option.canMoveBox"
            :fixed-box="option.fixedBox"
            :original="option.original"
            :auto-crop="option.autoCrop"
            :auto-crop-width="option.autoCropWidth"
            :auto-crop-height="option.autoCropHeight"
            :center-box="option.centerBox"
            :high="option.high"
          ></vue-cropper>
        </v-card-text>
        <v-card-actions class="mt-3 pb-4" style="margin-right: 50px">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize btnfont-style"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            text
            @click="CancelCrop()"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize btnfont-style btn_hover_effect"
            style="color: #ffff; border: 1px solid #a6cc39; border-radius: 8px"
            color="#A6CC39"
            width="100"
            @click="CropImage()"
            >{{ $t("submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- preview image -->
    <v-dialog
      v-model="previewimg"
      persistent
      width="800px"
      height="800px"
      overflow="hidden"
      content-class="elevation-0 preview_class"
    >
      <v-card overflow="hidden" color="transparent">
        <v-card-title style="padding-right: 0 !important">
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="previewimg = false"
            color="#FFFFFF"
            style="border-radius: 8px !important; border: 1px solid #ffffff30"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-img
          width="800px"
          height="500px"
          style="border-radius: 8px; margin: auto"
          :src="editVideoData.picturebackground"
        ></v-img>
      </v-card>
    </v-dialog>
    <!-- Participant list -->
    <v-dialog width="500" v-model="listDialog" scrollable>
      <v-card>
        <v-card-title>
          <v-tabs hide-slider v-model="tabs" class="pt-3">
            <v-tab
              href="#tab-1"
              class="font-style text-capitalize"
              style="
                width: 165px;
                border-radius: 10px 0px 0px 10px;
                border: 1px solid #9fc437;
              "
              >{{ $t("user") }}</v-tab
            >
            <v-tab
              href="#tab-2"
              style="
                width: 165px;
                border-radius: 0px 10px 10px 0px;
                border: 1px solid #9fc437;
              "
              class="font-style text-capitalize"
              >{{ $t("group") }}</v-tab
            >
          </v-tabs>
        </v-card-title>
        <div class="mt-p pt-3 ml-3 mr-8">
              <v-text-field
                outlined
                dense
                hide-details
                clearable
                placeholder="search..."
                v-model="searchUser"
                class="custom-padding mb-4 ml-3 mr-3"
              >
              </v-text-field>
            </div>
        <v-card-text style="height: 500px">
          <v-tabs-items v-model="tabs" class="mt-5">
            <v-tab-item value="tab-1">
              <v-data-table
                :items="filteredUsers"
                @toggle-select-all="SelectAllUser"
                item-key="userID"
                class="video-table"
                :loading="userLoading"
                loading-text="Loading... Please wait"
                v-model="selectedUserList"
                show-select
                :headers="userListHeader"
                hide-default-footer
                @page-count="userpageCount = $event"
                :page.sync="userpage"
                :no-data-text="$t('nodata')"
              >
                <template v-slot:no-data>
                  <p style="font-size: 16px">{{ $t("nodata") }}</p>
                </template>
              </v-data-table>
              <v-row no-gutters class="mt-1">
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="10"
                  lg="10"
                  xl="10"
                  class="p-0"
                >
                  <div class="d-flex text-left">
                    <v-pagination
                      circle
                      v-model="userpage"
                      class="pagination"
                      :length="userpageCount"
                    ></v-pagination>
                  </div>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <v-data-table
                :items="filteredDepartments"
                @toggle-select-all="SelectAllDept"
                item-key="groupID"
                :loading="departmentLoading"
                loading-text="Loading... Please wait"
                v-model="selectedDepartmentList"
                show-select
                :headers="departmentListHeader"
                hide-default-footer
                @page-count="deptpageCount = $event"
                :page.sync="deptpage"
                :no-data-text="$t('nodata')"
              >
                <template v-slot:no-data>
                  <p style="font-size: 16px">{{ $t("nodata") }}</p>
                </template>
              </v-data-table>
              <v-row no-gutters class="mt-1">
                <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10" class="p-0">
                  <div class="d-flex text-left">
                    <v-pagination
                      circle
                      v-model="deptpage"
                      class="pagination"
                      :length="deptpageCount"
                    ></v-pagination>
                  </div>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-3 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize font-style"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            text
            @click="listDialog = false"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize btnfont-style btn_hover_effect"
            style="color: #ffff; border: 1px solid #a6cc39; border-radius: 8px"
            color="#A6CC39"
            width="100"
            @click="savePermissionList()"
            >{{ $t("save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDialog" width="587" persistent>
      <v-card>
        <v-card-title
          style="font-size: 25px; color: #363636"
          class="justify-start"
          >{{ $t("deleteconfirm") }}</v-card-title
        >

        <v-card-text>
          <p
            class="mt-5"
            style="
              font-size: 20px;
              color: #363636;
              opacity: 1;
              text-align: start;
            "
          >
            {{ $t("suredelete") }} ?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize mb-3"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
              font-size: 20px;
            "
            width="100"
            text
            @click="confirmDialog = false"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize mb-3 white--text"
            width="100"
            style="font-size: 20px"
            color="#FF6060"
            @click="deleteVideoList()"
            >{{ $t("confirm") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { VueCropper } from "vue-cropper";
import { mapGetters } from "vuex";
export default {
  components: {
    VueCropper,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    ActiveNumberOfFilesCount: Number,
  },
  watch: {
    selectedUserList(val) {
      if (val.length != 0) {
        this.selectedDepartmentList = [];
        this.isUser = true;
      }
    },
    selectedDepartmentList(val) {
      if (val.length != 0) {
        this.selectedUserList = [];
        this.isUser = false;
      }
    },
    show(val) {
      if (val) {
        this.getSelectedVideo();
      }
    },
    dialog(val) {
      if (!val) {
        this.close();
      }
    },
  },
  computed: {
    filteredUsers() {
      if (this.tabs === 'tab-1' && this.searchUser) {
        return this.users.filter((user) =>
          user.employeeName.toLowerCase().includes(this.searchUser.toLowerCase())
        );
      } else {
        return this.users;
      }
    },
    filteredDepartments() {
  
      if (this.tabs === 'tab-2' && this.searchUser) {
        return this.departments.filter((department) =>
          department.groupName.toLowerCase().includes(this.searchUser.toLowerCase())
        );
      } else {
        return this.departments;
      }
    },
    ...mapGetters(["editVideoData"]),
    timesRules() {
      return [(v) => !!v || ""];
    },
    dialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
    activeNumFiles_Count: {
      get() {
        return this.ActiveNumberOfFilesCount;
      },
      set(e) {
        return e;
      },
    },
    userListHeader() {
      return [
        {
          text: this.$t("name"),
          align: "left",
          value: "fullName",
        },
      ];
    },
    departmentListHeader() {
      return [
        {
          text: this.$t("name"),
          align: "left",
          value: "groupName",
        },
      ];
    },
    videoHeaders() {
      return [
        {
          text: "#",
          sortable: false,
          value: "part",
        },
        {
          text: this.$t("permission"),
          sortable: false,
          value: "permission",
        },
        {
          text: this.$t("filenameL"),
          sortable: false,
          value: "songName",
        },
        {
          text: this.$t("timesm"),
          sortable: false,
          value: "duration",
        },
        {
          text: this.$t("sort"),
          align: "left",
          value: "sort",
          sortable: false,
        },
        {
          text: this.$t("action"),
          sortable: false,
          value: "actions",
          align: "center",
          width: "120px",
          //width: "385px"
        },
      ];
    },
    titleRules() {
      return [(v) => !!v || "Title is required"];
    },
    selectedHeader() {
      return [
        {
          text: "#",
          value: "icon",
          sortable: false,
          width: "30px",
        },
        {
          text: this.$t("name"),
          value: "fullName",
        },
        // {
        //   text: this.$t('viewpermission'),
        //   value: "permission"
        // },
        {
          text: this.$t("request"),
          value: "request",
          sortable: false,
          width: "165px",
          align: "end",
        },
        // {
        //   text: this.$t('repeat'),
        //   value: "times"
        // },
        {
          text: this.$t("expiredate"),
          value: "perDay",
          width: "250px",
        },
        {
          text: this.$t("action"),
          value: "index",
          sortable: false,
          width: "120px",
          align: "center",
        },
      ];
    },
    startContentDate: {
      get() {
        if (this.editVideoData.startContentDate)
          return this.editVideoData.startContentDate.split("T")[0];
        else return false;
      },
      set(e) {
        this.editVideoData.startContentDate = e;
      },
    },
    endContentDate: {
      get() {
        if (this.editVideoData.startContentDate)
          return this.editVideoData.endContentDate.split("T")[0];
        else return false;
      },
      set(e) {
        this.editVideoData.endContentDate = e;
      },
    },
  },
  data: () => ({
    inbox_flag: false,
    pushNoti_flag: false,
    email_flag: false,
    line_flag: false,
    selectedRadio: null,
    searchUser: null,
    status: true,
    switch1: true,
    previewimg: false,
    expanded: [],
    permissionChevron: false,
    showEditImgTool: false,
    sortNumberItems: [],
    perDayRules: {
      //required: v => v.length > 11 || '',
      required: (v) => v.split("-").length > 2 || "",
    },
    dialogCrop: false,
    page: 1,
    pageCount: 0,
    userpage: 1,
    userpageCount: 0,
    deptpage: 1,
    deptpageCount: 0,
    option: {
      img: "",
      size: 1,
      full: false,
      outputType: "png",
      canMove: true,
      fixedBox: true,
      original: false,
      canMoveBox: false,
      autoCrop: true,
      autoCropWidth: 800,
      autoCropHeight: 450,
      centerBox: false,
      high: true,
    },
    fileError: false,
    video: {
      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      file: null,
      fileUrl: "",
      fileName: "",
    },
    videoList: [],
    startDateMenu: false,
    endDateMenu: false,
    showPermission: false,
    confirmDialog: false,
    videoIndex: 0,
    listDialog: false,
    selectedUserList: [],
    selectedDepartmentList: [],
    tabs: "tab-1",
    users: [],
    departments: [],
    userLoading: true,
    departmentLoading: true,
    showVideoList: false,
    showedPermissionIndex: null,
    isUser: false,
    selectedList: [],
    showSelectedList: false,
    loading: false,
    userdataList: [],
    departmentdataList: [],
    allView: false,
    originalVideoLength: 0,
    perList: [],
    PricingData: [],
    soundLoading: false,
    actualduration: "",
  }),
  mounted() {
    this.getUserData();
    this.getDepartment();
    this.getPricingData();
  },
  methods: {
    handleCheckboxChange() {
      if (this.email_flag) {
       
        this.selectedRadio = 'thai';
      } else {
       
        this.selectedRadio = null;
      }
    },
    selectNull(v) {
      if (v == "") {
        return null;
      } else {
        return v;
      }
    },
    async getPricingData() {
      const res = await this.$axios.get(
        `${this.web_urlV4}Package/GetPackageOfCompanyCustomer?CompanyId=` +
          localStorage.getItem("companyID")
      );
      this.PricingData = res.data.data;
    },
    dropImg(e) {
      let file = e.dataTransfer.files[0];
      if (!/(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(file.name)) {
        alert("Please choose image!");
        return false;
      }
      this.video.fileName = file.name;
      let reader = new FileReader();
      reader.onload = (f) => {
        let src;
        if (typeof f.target.result === "object") {
          src = window.URL.createObjectURL(new Blob([f.target.result]));
        } else {
          src = f.target.result;
        }
        this.option.img = src;
        this.dialogCrop = true;
      };
      reader.readAsArrayBuffer(file);
    },
    uploadImg(e, num) {
      let self = this;
      let file = e.target.files[0];
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert("Please choose image!");
        return false;
      }
      this.video.fileName = file.name;
      let reader = new FileReader();
      reader.onload = function (f) {
        let data;
        if (typeof f.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([f.target.result]));
        } else {
          data = f.target.result;
        }
        if (num === 1) {
          self.option.img = data;
          self.dialogCrop = true;
        }
      };
      reader.readAsArrayBuffer(file);
    },
    CancelCrop() {
      this.dialogCrop = false;
    },
    SelectAllUser() {
      if (this.selectedUserList == undefined) {
        this.selectedUserList = this.users;
      } else if (this.selectedUserList.length == 0) {
        this.selectedUserList = this.users;
      } else {
        this.selectedUserList = [];
      }
    },
    SelectAllDept() {
      if (this.selectedDepartmentList == undefined) {
        this.selectedDepartmentList = this.departments;
      } else if (this.selectedDepartmentList.length == 0) {
        this.selectedDepartmentList = this.departments;
      } else {
        this.selectedDepartmentList = [];
      }
    },
    CropImage() {
      this.$refs.cropper.getCropData((data) => {
        this.getFile(data);
        this.dialogCrop = false;
      });
    },
    allowedStartDates: (val) => val >= new Date().toISOString().substr(0, 10),
    allowedEndDates(val) {
      return val >= this.video.startDate;
    },
    allowedPerday(val) {
      return val <= this.endContentDate && val >= this.startContentDate;
    },
    editcalcRes(e) {
      this.endContentDate = e;
    },
    startContentDateDis(selected) {
      this.startDateMenu = false;
      if (selected > this.endContentDate) {
        this.endContentDate = selected;
      }
    },
    checkStatus(item) {
      if (item) {
        return item;
      } else {
        return "Please Select"; //Date Picker
      }
    },

    async getUserData() {
      this.userLoading = true;
      const res = await this.$axios.get(
        `${this.web_urlV6}EmployeeEXT/GetEmployeeExtAllV4?id=` +
          localStorage.getItem("companyID")
      );
      this.users = res.data.data.map((v, i) => ({
        ...v,
        index: i,
      }));
      this.userLoading = false;
    },

    async getDepartment() {
      this.departmentLoading = true;
      const res = await this.$axios.get(
        `${this.web_url}Group/GetGroupALL?id=` +
          localStorage.getItem("companyID")
      );
      this.departments = res.data.data.map((v, i) => ({
        ...v,
        index: i,
      }));
      this.departmentLoading = false;
    },
    getFile(e) {
      this.video.fileUrl = e;
      this.video.file = e;
      this.editVideoData.picturebackground = e;
      this.showEditImgTool = false;
    },

    imageTobase64(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.video.file = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },

    async getVideo(e) {
      this.soundLoading = true;
      if (
        this.activeNumFiles_Count + this.videoList.length <
        this.PricingData.numberOfFilesId
      ) {
        let filesize = e.target.files[0].size;

        if (filesize <= 157286400) {
          const video = document.createElement("video");
          video.addEventListener("loadedmetadata", () => {
            this.actualduration = video.duration;
          });
          video.src = URL.createObjectURL(e.target.files[0]);
          let formData = new FormData();
          formData.append("file", e.target.files[0]);
          const res = await this.$axios.post(
            `${this.web_url}File/UploadFile`,
            formData
          );
          this.videoList.push({
            isUserGroup: this.isUser ? 2 : 1,
            permissionData: [],
            videoFile: res.data.data,
            size:
              parseFloat(e.target.files[0].size / 1000000).toFixed(2) + "MB",
            songName: e.target.files[0].name,
            part: parseInt(this.originalVideoLength + 1),
            index: parseInt(this.originalVideoLength + 1),
            time: Math.trunc(this.actualduration).toString(),
            duration: this.formatDuration(this.actualduration),
            allView: true,
          });
          this.showVideoList = true;
          this.originalVideoLength = this.originalVideoLength + 1;

          let temp_Item = this.videoList;
          //sorting....
          let sort_temp1 = temp_Item
            .filter((x) => x.sort != null && x.sort != 0)
            .map((v) => ({
              ...v,
            })); //sort number included array

          let sort_temp2 = temp_Item
            .filter((x) => x.sort == null || x.sort == 0)
            .map((v) => ({
              ...v,
            })); //sort number un-included array

          sort_temp1.sort((a, b) => {
            if (a.sort === b.sort) {
              let asort = a.sort < b.sort ? -1 : 1;
              return asort;
            } else {
              return a.sort < b.sort ? -1 : 1;
            }
          }); //sort by selected sort numberr...

          this.videoList = sort_temp1.concat(sort_temp2).map((v, i) => ({
            ...v,
            no: i + 1,
            part: parseInt(i + 1), //"Part " +
          }));

          let temp_null = [{ value: "" }];
          let temp_num = this.videoList.map((v, i) => {
            return { value: i + 1 };
          });
          this.sortNumberItems = temp_null.concat(temp_num);
        } else {
          alert("Your file size is over 150MB!");
        }
      } else {
        alert(
          "Unable to add new ones because the number of files has reached the limit. Please contact Seedkm administrator to enhance usage!"
        );
      }
      this.soundLoading = false;
      e.target.value = null;
    },

    getVideoDuration(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const media = new Audio(reader.result);
          media.onloadedmetadata = () => resolve(media.duration);
        };
        reader.readAsDataURL(file);
        reader.onerror = (error) => reject(error);
      });
    },
    async checkItemDelete(item) {
      let self = this;
      self.confirmDialog = true;
      self.videoIndex = item.index;
    },
    deleteVideoList() {
      this.videoList.splice(
        this.videoList.findIndex((item) => item.index === this.videoIndex),
        1
      );
      let index = this.sortNumberItems.length - 1;
      this.sortNumberItems.splice(index, 1);

      this.confirmDialog = false;
      if (this.videoList.length == 0) {
        this.showVideoList = false;
        this.showPermission = false;
      }
    },
    DeletefromUser(i) {
      this.selectedList.splice(
        this.selectedList.findIndex((item) => item.index === i.index),
        1
      );
      this.perList.splice(
        this.perList.findIndex((item) => item.index === i.index),
        1
      );
      this.videoList[this.videoIndex].permissionData = this.selectedList;
    },
    checkAllView(newVal) {
      this.videoList.forEach((element) => {
        if (element.index == this.showedPermissionIndex - 1) {
          element.allView = newVal;
        }
      });
    },
    checkPermission(e) {
      this.selectedList = [];
      if (e.permissionData.length > 0) {
        for (let i = 0; i < e.permissionData.length; i++) {
          this.selectedList.push({
            userPermissionID: e.permissionData[i].userPermissionID,
            videoPermissionID: e.permissionData[i].videoPermissionID,
            fullName: e.permissionData[i].fullName,
            isComplete: e.permissionData[i].isComplete,
            isUser: e.permissionData[i].isUser,
            request: e.permissionData[i].request,
            perDay: e.permissionData[i].perDay,
            selectedDate: e.permissionData[i].selectedDate,
            times: e.permissionData[i].times,
            videoLessonID: e.permissionData[i].videoLessonID,
            index: i,
          });
          if (e.isUserGroup == 2) {
            this.tabs = "tab-1";
            const found = this.users.find(
              (c) => c.userID == e.permissionData[i].userPermissionID
            );
            this.selectedUserList.push(found);
            this.perList = this.selectedUserList;
          } else {
            this.tabs = "tab-2";
            this.selectedDepartmentList.push({
              groupID: e.permissionData[i].userPermissionID,
              groupName: e.permissionData[i].fullName,
            });
            this.perList = this.selectedDepartmentList;
          }
        }
      }
      this.videoIndex = e.index;
      this.showedPermissionIndex = e.part;
      this.showSelectedList = true;
      this.userdataList = [];
      this.departmentdataList = [];
      this.allView = e.allView == null ? false : e.allView;
    },
    AddParticipant() {
      let that = this;
      that.listDialog = true;
      if (this.isUser) {
        this.selectedUserList = this.perList;
      } else {
        this.selectedDepartmentList = this.perList;
      }
    },
    savePermissionList() {
      this.listDialog = false;
      let users = [];
      let departments = [];
      if (this.isUser) {
        for (const element of this.selectedUserList) {
          const found = this.selectedList.find(
            (c) => c.userPermissionID == element.userID
          );
          if (found == undefined) {
            const filteruserdata = this.userdataList.find(
              (c) => c.employeeID == element.userID
            );
            if (filteruserdata == undefined) {
              users.push({
                employeeID: element.userID,
                fullName: element.fullName,
                isCheck: true,
                isUser: true,
                perDay: element.perDay,
                permission: false,
                request: false,
                selectedDate: "Please Select", //Date Picker
                times: 1,
                userPermissionID: element.userID,
                index: element.index,
              });
            } else {
              users.push({
                employeeID: filteruserdata.employeeID,
                fullName: filteruserdata.fullName,
                isCheck: filteruserdata.isCheck,
                isUser: filteruserdata.isUser,
                perDay: filteruserdata.perDay,
                permission: filteruserdata.permission,
                request: filteruserdata.request,
                selectedDate: filteruserdata.selectedDate,
                times: filteruserdata.times,
                userPermissionID: filteruserdata.userPermissionID,
                index: filteruserdata.index,
              });
            }
          } else {
            users.push({
              employeeID: found.userPermissionID,
              fullName: found.fullName,
              isCheck: true,
              isUser: found.isUser,
              perDay: found.perDay,
              permission: found.permission,
              request: found.request,
              selectedDate: found.selectedDate,
              times: found.times,
              userPermissionID: found.userPermissionID,
              index: found.index,
            });
          }
        }
      } else {
        for (const element of this.selectedDepartmentList) {
          const found1 = this.selectedList.find(
            (c) => c.userPermissionID == element.groupID
          );
          if (found1 == undefined) {
            const filterdepartdata = this.departmentdataList.find(
              (c) => c.employeeID == element.groupID
            );
            if (filterdepartdata == undefined) {
              departments.push({
                employeeID: element.groupID,
                fullName: element.groupName,
                isCheck: true,
                isUser: false,
                perDay: element.perDay,
                permission: false,
                request: false,
                selectedDate: "Please Select", //Date Picker
                times: 1,
                userPermissionID: element.groupID,
                index: element.index,
              });
            } else {
              departments.push({
                employeeID: filterdepartdata.employeeID,
                fullName: filterdepartdata.fullName,
                isCheck: filterdepartdata.isCheck,
                isUser: filterdepartdata.isUser,
                perDay: filterdepartdata.perDay,
                permission: filterdepartdata.permission,
                request: filterdepartdata.request,
                selectedDate: filterdepartdata.selectedDate,
                times: filterdepartdata.times,
                userPermissionID: filterdepartdata.userPermissionID,
                index: filterdepartdata.index,
              });
            }
          } else {
            departments.push({
              employeeID: found1.userPermissionID,
              fullName: found1.fullName,
              isCheck: true,
              isUser: found1.isUser,
              perDay: found1.perDay,
              permission: found1.permission,
              request: found1.request,
              selectedDate: found1.selectedDate,
              times: found1.times,
              userPermissionID: found1.userPermissionID,
              index: found1.index,
            });
          }
        }
      }
      this.selectedList = this.isUser ? users : departments;
      this.userdataList = users;
      this.departmentdataList = departments;
      this.perList = this.isUser
        ? this.selectedUserList
        : this.selectedDepartmentList;
      for (let v of this.videoList) {
        if (this.showedPermissionIndex == v.part) {
          v.isUserGroup = this.isUser ? 2 : 1;
          v.permissionData = this.selectedList;
        }
      }
    },

    getSelectedVideo() {
      this.videoList = this.editVideoData.partfileList.map((v, i) => ({
        ...v,
        part: i + 1,
        index: i,
        time: Math.trunc(v.time).toString(),
        duration: this.formatDuration(v.time),
      }));
      this.originalVideoLength = this.editVideoData.partfileList.length;
      this.activeNumFiles_Count =
        this.activeNumFiles_Count - this.videoList.length;

      let temp_Item = this.videoList;
      //sorting....
      let sort_temp1 = temp_Item
        .filter((x) => x.sort != null && x.sort != 0)
        .map((v) => ({
          ...v,
        })); //sort number included array

      let sort_temp2 = temp_Item
        .filter((x) => x.sort == null || x.sort == 0)
        .map((v) => ({
          ...v,
        })); //sort number un-included array

      sort_temp1.sort((a, b) => {
        if (a.sort === b.sort) {
          let asort = a.sort < b.sort ? -1 : 1;
          return asort;
        } else {
          return a.sort < b.sort ? -1 : 1;
        }
      }); //sort by selected sort numberr...
      //sort_temp2.sort((a, b) => (a.duration > b.duration ? 1 : -1)); //sortb by topic tiltes...

      this.videoList = sort_temp1.concat(sort_temp2).map((v, i) => ({
        ...v,
        no: i + 1,
        part: parseInt(i + 1), //"Part " +
      }));

      let temp_null = [{ value: "" }];
      let temp_num = this.videoList.map((v, i) => {
        return { value: i + 1 };
      });
      this.sortNumberItems = temp_null.concat(temp_num);
    },

    close() {
      this.showSelectedList = false;
      this.videoList = [];
      this.showedPermissionIndex = null;
      this.selectedUserList = [];
      this.selectedDepartmentList = [];
      this.userdataList = [];
      this.departmentdataList = [];
      this.$emit("close");
    },

    async submit() {
      let that = this;
      if (that.$refs.form.validate()) {
        that.loading = true;
        const base64Res = await that.$axios.post(
          `${that.web_url}File/UploadFileStringBase`,
          {
            base64: that.video.file,
            fileName: that.video.fileName,
          }
        );
        let temp = [];
        for (const element of that.selectedList) {
          temp.push({
            musicPermissionID: element.musicPermissionID,
            userPermissionID: element.userPermissionID,
            musicLessonID: element.musicLessonID,
            request: element.request,
            isUser: element.isUser,
            isComplete: element.isComplete,
            times: element.times,
            perDay: element.perDay,
          });
        }
        for (let v of that.videoList) {
          if (that.showedPermissionIndex == v.part) {
            v.isUserGroup = that.isUser ? 2 : 1;
            v.permissionData = temp;
          }
        }
        const userdata = JSON.parse(
          localStorage.getItem("vivek_authenticated_user_data")
        );
        const memberid = userdata.memberId;
        const data = {
          id: that.editVideoData.id,
          allowDownload: false,
          companyId: localStorage.getItem("companyID"),
          endContentDate: that.editVideoData.endContentDate,
          lessonName: that.editVideoData.lessonName,
          partfileList: that.videoList,
          picturebackground: that.video.file
            ? base64Res.data
            : that.editVideoData.picturebackground,
          startContentDate: that.editVideoData.startContentDate,
          topicID: that.editVideoData.topicID,
          wordDescription: that.editVideoData.wordDescription,
          imgBase64: that.video.file,
          updateBy: memberid,
          inboxFlag: this.inbox_flag,
          emailFlag: this.email_flag,
          pushNotiFlag: this.pushNoti_flag,
          emailLanguage: this.selectedRadio,
          lineFlag: this.line_flag,
          viewerFlag: this.editVideoData.viewerFlag,
        };
      
        await that.$axios
          .post(`${that.web_url}GuidedLesson/UpdateGuidedContent`, data)
          .then(function (res) {
            if (res.data.status == 0) {
              that.showSelectedList = false;
              that.videoList = [];
              that.showedPermissionIndex = null;
              that.selectedUserList = [];
              that.selectedDepartmentList = [];
              that.userdataList = [];
              that.departmentdataList = [];
              that.originalVideoLength = 0;
              that.perList = [];
              that.$emit("confirm");
            }
            that.inbox_flag = false;
            that.email_flag = false;
            that.pushNoti_flag = false;
            that.line_flag = false;
          });

        that.loading = false;
      } else {
        alert("Incomplete information Please enter again!");
      }
    },
  },
};
</script>

<style scoped>
.radio-group {
  display: flex;
  align-items: center;
}
.radiobutton{
  margin-top: -10px;
}
.radio-wrapper {
  position: relative;
  width: 22px;
  height: 22px;
}

.radio-placeholder,
.radio-button {
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.radio-placeholder {
  background-color:#424242;
  opacity: 0.1;
  pointer-events: none; /* Prevent interaction */
}

.editonoffswitch {
  position: relative;
  width: 78px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.editonoffswitch-checkbox {
  display: none;
}

.editonoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #999999;
  border-radius: 20px;
}

.editonoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.editonoffswitch-inner:before,
.editonoffswitch-inner:after {
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 15px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.editonoffswitch-inner:before {
  content: "ON";
  padding-left: 11px;
  background-color: #a6cc39;
  color: #ffffff;
}

.editonoffswitch-inner:after {
  content: "OFF";
  padding-right: 11px;
  color: #ffffff;
  background-color: #707070;
  opacity: 1;
  text-align: right;
}

.editonoffswitch-switch {
  width: 26px;
  height: 26px;
  margin: 2px;
  background: #ffffff;
  border: 2px solid #999999;
  border-radius: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 44px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.editonoffswitch-checkbox:checked
  + .editonoffswitch-label
  .editonoffswitch-inner {
  margin-left: 0;
}

.editonoffswitch-checkbox:checked
  + .editonoffswitch-label
  .editonoffswitch-switch {
  right: 0px;
}
/* end switch */
.labelswitch {
  margin-left: 7.5rem;
  margin-top: -3.5rem;
}
.labelswitchs {
  margin-left: 7.5rem;
  margin-top: 0.5rem;
}
.classdivider {
  margin-top: -7px;
}

.customcardnoti {
  width: 98%;
  margin-left: 10px;
  background-color: #e0e0e0;
}
::v-deep .v-dialog {
  border-radius: 10px !important;
}
::v-deep .preview_class.v-dialog {
  border-radius: 8px !important;
}
::v-deep .v-text-field__slot {
  font-size: 16px !important;
}

.font-style {
  font-size: 16px;
  color: #4d4f5c;
}
.btnfont-style {
  font-size: 20px;
}

::v-deep .v-input__slot {
  border-radius: 10px !important;
}

::v-deep .video-table tr:nth-child(even) {
  background: unset;
}

::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 16px !important;
  color: #47484b !important;
  background-color: #e0e0e0 !important;
  opacity: 1;
}

::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
}

::v-deep .primary--text {
  color: #a6cc39 !important;
}
::v-deep .primary {
  background-color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
::v-deep .accent {
  background-color: #a6cc39 !important;
}
::v-deep .accent--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
}

::v-deep .v-tab--active {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
}
::v-deep .v-simple-checkbox .mdi-checkbox-blank-outline {
  color: #c0c0c0 !important;
}
::v-deep .v-simple-checkbox .mdi-checkbox-marked {
  color: #a6cc39 !important;
}
::v-deep .v-simple-checkbox .mdi-minus-box {
  color: #a6cc39 !important;
}
.loading-circle {
  position: fixed;
  z-index: 5;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}
::v-deep .chkAllow .v-label {
  font-size: 18px !important;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* For Sort menu. */
::v-deep .sort_Btn.v-btn:not(.v-btn--round).v-size--default {
  min-width: 50px;
  font-size: 18px;
  padding: 0 0;
  border: 1px solid #47484b;
  opacity: 1 !important;
}
::v-deep .v-list.overflow-y-auto.v-sheet.theme--light::-webkit-scrollbar {
  width: 7px !important;
}
/* Track */
::v-deep .v-list.overflow-y-auto.v-sheet.theme--light::-webkit-scrollbar-track {
  width: 6px !important;
  background: #e6e6e6;
  border-left: 1px solid #dadada;
}
/* Handle */
::v-deep .v-list.overflow-y-auto.v-sheet.theme--light::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 2px #e6e6e6;
  border-radius: 7px;
}
::v-deep .v-list.overflow-y-auto.v-sheet.theme--light {
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
}
::v-deep .v-list-item {
  min-height: 32px;
  border-top: 2px solid #707070;
}
::v-deep .v-list-item:hover {
  background-color: #707070 !important;
}
::v-deep
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  color: #ffffff !important;
}
.list-item-padding.v-sheet.v-list {
  border-radius: 8px !important;
}
.list-item-padding.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  border-radius: 8px !important;
}
.list-item-padding.list-item-padding.theme--light {
  padding: 0 5px !important;
  min-height: 42px !important;
}
::v-deep .list-item-padding.preview.v-list-item.theme--light {
  border-top: unset !important;
}
::v-deep .list-item-padding.v-list-item:hover {
  background-color: unset !important;
}
.btn_hover_effect,
.reset-btn {
  color: #fff;
  width: 80%;
  font-size: 18px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.btn_hover_effect:before,
.reset-btn:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.btn_hover_effect:hover:before,
.reset-btn:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.btn_hover_effect:hover,
.reset-btn:hover {
  transition: 0.25s;
}
.btn_hover_effect:after,
.reset-btn:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
</style>
<style scoped>
::v-deep
  .child_table.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 16px !important;
  color: #424242 !important;
  background-color: rgb(166, 204, 57, 0.2) !important;
  opacity: 1;
}
::v-deep
  .child_table.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #424242 !important;
  background-color: #ffffff !important;
  opacity: 1;
  vertical-align: center;
}
::v-deep .v-data-table thead th:first-child {
  border-radius: 8px 0 0 8px;
}
::v-deep .v-data-table thead th:last-child {
  border-radius: 0 8px 8px 0;
}
::v-deep
  .v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: unset !important;
}
</style>
